/*
 *
 * ReturnForm constants
 *
 */

export const STATUS_TYPE_RECEIVED = 'RECEIVED';
export const STATUS_TYPE_PREPARATION = 'PREPARATION';
export const STATUS_TYPE_SHIPPED = 'SHIPPED';
export const STATUS_TYPE_DELIVERED = 'DELIVERED';
export const STATUS_TYPE_CANCELED = 'CANCELED';
export const STATUS_TYPE_NOTDELIVERED = 'NOT_DELIVERED';
export const STATUS_TYPE_FOLLOWUP = 'FOLLOW_UP';
export const STATUS_TYPE_ARRANGED = 'ARRANGED';