import React from 'react';
import { Col, Divider, Layout, Row } from 'antd';
import ItemsTable from './ItemsTable';
import DetailsSection from './DetailsSection';
import { useIntl } from 'react-intl';
import messages from './messages';

const ReturnForm = ({ order, setDataProcessed, dataProcessed }) => {
  const intl = useIntl();

  return (
    <Layout style={{ height: 'calc(100vh - 128px)' }}>
      <Row
        gutter={{ xs: 24, sm: 16, md: 16, lg: 8, xxl: 8 }}
        style={{
          padding: 15,
          marginLeft: 0,
          marginRight: 0,
          height: 'auto',
          overflow: 'auto',
        }}
      >
        <DetailsSection data={order} />
        <Col span={24}>
          <Divider orientation="middle">
            {intl.formatMessage(messages.returnFormItems)}
          </Divider>
          <ItemsTable data={order?.positions} loading={order?.loading} />
        </Col>
      </Row>
    </Layout>
  );
};

export default ReturnForm;
