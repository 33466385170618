import { defineMessages } from 'react-intl';

export default defineMessages({
  returnFormItems: {
    id: `returnFormItems`,
    defaultMessage: 'Items',
  },
  returnFormOrder: {
    id: `returnFormOrder`,
    defaultMessage: 'Order',
  },
  returnFormOrderNumber: {
    id: `returnFormOrderNumber`,
    defaultMessage: 'Number: {number}',
  },
  returnFormCustomer: {
    id: `returnFormCustomer`,
    defaultMessage: 'Customer',
  },
  returnFormCustomerName: {
    id: `returnFormCustomerName`,
    defaultMessage: 'Name: {name}',
  },
  returnFormCustomerExtra: {
    id: `returnFormCustomerExtra`,
    defaultMessage: 'Extra: {extra}',
  },
  returnFormCustomerStreet: {
    id: `returnFormCustomerStreet`,
    defaultMessage: 'Street: {street}',
  },
  returnFormCustomerZipCity: {
    id: `returnFormCustomerZipCity`,
    defaultMessage: 'City: {city}',
  },
  returnFormCustomerCountry: {
    id: `returnFormCustomerCountry`,
    defaultMessage: 'Country: {country}',
  },
  returnFormCustomerEmail: {
    id: `returnFormCustomerEmail`,
    defaultMessage: 'Email: {email}',
  },
  positionNumber: {
    id: `positionNumber`,
    defaultMessage: 'Position',
  },
  itemName: {
    id: `itemName`,
    defaultMessage: 'Name',
  },
  itemNumber: {
    id: `itemNumber`,
    defaultMessage: 'Number',
  },
  quantity: {
    id: `quantity`,
    defaultMessage: 'Quantity',
  },
  status: {
    id: `status`,
    defaultMessage: 'Status',
  },
  statusReceived: {
    id: `statusReceived`,
    defaultMessage: 'Received',
  },
  statusPreparation: {
    id: `statusPreparation`,
    defaultMessage: 'Preparation',
  },
  statusShipped: {
    id: `statusShipped`,
    defaultMessage: 'Shipped',
  },
  statusDelivered: {
    id: `statusDelivered`,
    defaultMessage: 'Delivered',
  },
  statusCanceled: {
    id: `statusCanceled`,
    defaultMessage: 'Canceled',
  },
  statusNotDelivered: {
    id: `statusNotDelivered`,
    defaultMessage: 'Not delivered',
  },
  statusFollowUp: {
    id: `statusFollowUp`,
    defaultMessage: 'Follow up',
  },
  statusArranged: {
    id: `statusArranged`,
    defaultMessage: 'Arranged',
  },
});
