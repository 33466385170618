import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  STATUS_TYPE_ARRANGED,
  STATUS_TYPE_CANCELED,
  STATUS_TYPE_DELIVERED,
  STATUS_TYPE_FOLLOWUP,
  STATUS_TYPE_NOTDELIVERED,
  STATUS_TYPE_PREPARATION,
  STATUS_TYPE_RECEIVED,
  STATUS_TYPE_SHIPPED,
} from './constants';

const getStatusTypes = (intl) => [
  {
    label: intl.formatMessage(messages.statusReceived),
    value: STATUS_TYPE_RECEIVED,
  },
  {
    label: intl.formatMessage(messages.statusPreparation),
    value: STATUS_TYPE_PREPARATION,
  },
  {
    label: intl.formatMessage(messages.statusShipped),
    value: STATUS_TYPE_SHIPPED,
  },
  {
    label: intl.formatMessage(messages.statusDelivered),
    value: STATUS_TYPE_DELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusCanceled),
    value: STATUS_TYPE_CANCELED,
  },
  {
    label: intl.formatMessage(messages.statusNotDelivered),
    value: STATUS_TYPE_NOTDELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusFollowUp),
    value: STATUS_TYPE_FOLLOWUP,
  },
  {
    label: intl.formatMessage(messages.statusArranged),
    value: STATUS_TYPE_ARRANGED,
  },
];

const getColumns = (intl) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
  },
  {
    title: intl.formatMessage(messages.quantity),
    dataIndex: 'totalQuantity',
  },
  {
    title: intl.formatMessage(messages.status),
    render: (text) =>
      getStatusTypes(intl).find((element) => element.value === text)?.label,
    dataIndex: 'positionStatus',
  },
];

const ItemsTable = ({ data = [], loading }) => {
  const intl = useIntl();

  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={loading}
      bordered
      size="small"
      rowKey="positionNumber"
    />
  );
};

ItemsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default ItemsTable;
